import { createGlobalStyle } from 'styled-components';
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/700.css";

function LightenDarkenColor(col, amt) {
  
  var usePound = false;

  if (col[0] == "#") {
      col = col.slice(1);
      usePound = true;
  }

  var num = parseInt(col,16);

  var r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if  (r < 0) r = 0;

  var b = ((num >> 8) & 0x00FF) + amt;

  if (b > 255) b = 255;
  else if  (b < 0) b = 0;

  var g = (num & 0x0000FF) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);

}

var lightenPrimary = props => {
  return LightenDarkenColor(props.primaryColor?.hex || "#000", 50); 
}

var lightenSecondary = props => {
  return LightenDarkenColor(props.secondaryColor?.hex || "#000", 20); 
}

var moreLighterPrimary = props => {
  return LightenDarkenColor(props.primaryColor?.hex || "#000", 90)
}

function getPerceivedLightness(hex) {
  const r = parseInt(hex.substring(1, 3), 16);
  const g = parseInt(hex.substring(3, 5), 16);
  const b = parseInt(hex.substring(5, 7), 16);

  const perceivedRed = r * 0.2126;
  const perceivedGreen = g * 0.7152;
  const perceivedBlue = b * 0.0722;

  const sum = perceivedRed + perceivedGreen + perceivedBlue;
  const perceivedLightness = sum / 255;

  return perceivedLightness;
}

const GlobalStyles = createGlobalStyle`

@font-face {
  font-family: 'playfair';
  src: url('/PlayfairDisplay-VariableFont_wght.ttf') format("truetype");
}

:root {
    --primary: ${props => props.primaryColor ?  props.primaryColor.hex : '#BADA55' };
    --secondary: ${props => props.secondaryColor ? props.secondaryColor.hex : '#AB986D'};
    --white: #fff;
    --red: #eb4335;
    --green: #309975;
    --bgWhite: #F8F8F8;
    --black: #050505;
    --grey: #6E6E6E;
    --smokeGrey: #F5F4F3;
    --drkGrey: #989898;
    --darkGrey: #424242;
    --borderGrey: #D9D9D9;
    --mdGrey: #4a4a4a;
    --primaryLR: #410987;
    --sharkGrey:'#191B1B';
    --lightenButton: ${lightenSecondary};
    --lightenPrimary: ${lightenPrimary};
    --moreLighterPrimary: ${moreLighterPrimary};
    --width: 100%;
    --gutter: 1%;

    --threshold: 0.6;
    --perceived-lightness-primary: ${props => getPerceivedLightness(props.primaryColor?.hex)};
    --perceived-lightness-secondary: ${props => getPerceivedLightness(props.secondaryColor?.hex)};
    // These colours are white or black, depending on the brightness of the primary or secondary colour.
    --primaryText: hsl(0, 0%, calc((var(--perceived-lightness-primary) - var(--threshold)) * -10000000%));
    --secondaryText: hsl(0, 0%, calc((var(--perceived-lightness-secondary) - var(--threshold)) * -10000000%));
  
  
  }

  html {
    background: var(--bgWhite);
    font-size: 16px;
    overflow-x: hidden;

    &.cal-open {
      overflow-y: hidden;
    }
  }

  body {
    font-size: .875rem;
    font-family: Montserrat, sans-serif;
    overflow: hidden;
  }

  h1, h2 {
    font-family: ${props => props.app === "LUXH" ? "playfair, Montserrat, sans-serif" : "Montserrat, sans-serif"};
    font-weight: ${props => props.app === "LUXH" ? "500" : "700"};
  }

  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }

  h1, h2, h3, h4, h5, h6, p, ul, li, ol {
    margin: 0;
    padding: 0;
  }
  
  p {
    line-height: 1.4;
  }

  img {
    max-width: 100%;
  }

  /* container styles*/

  .container {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    position: relative;
  }

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12 {
    width: var(--width);
    float: left;
    margin: 0;
    min-height: 0.125rem;
  }

  .pad {
    padding: 7.438rem 0;
  }

  .hidden-m {
    display: none;
  }

  /* desktop */ 

  @media only screen and (min-width: 1024px) {
    html.cal-open {
      overflow-y: auto;
    }

    .container {
        max-width: 90rem; /*1440px*/
    }

    .col-1 { width: calc((var(--width) / 12) - (var(--gutter) * 11 / 12)); }
    .col-2 { width: calc((var(--width) / 6) - (var(--gutter) * 10 / 12)); }
    .col-3 { width: calc((var(--width) / 4) - (var(--gutter) * 9 / 12)); }
    .col-4 { width: calc((var(--width) / 3) - (var(--gutter) * 8 / 12)); }
    .col-5 { width: calc((var(--width) / (12 / 5)) - (var(--gutter) * 7 / 12)); }
    .col-6 { width: calc((var(--width) / 2) - (var(--gutter) * 6 / 12)); }
    .col-7 { width: calc((var(--width) / (12 / 7)) - (var(--gutter) * 5 / 12)); }
    .col-8 { width: calc((var(--width) / (12 / 8)) - (var(--gutter) * 4 / 12)); }
    .col-9 { width: calc((var(--width) / (12 / 9)) - (var(--gutter) * 3 / 12)); }
    .col-10 { width: calc((var(--width) / (12 / 10)) - (var(--gutter) * 2 / 12)); }
    .col-11 { width: calc((var(--width) / (12 / 11)) - (var(--gutter) * 1 / 12)); }
    .col-12 { width: var(--width); }

    .hidden-m {
      display: initial;
    }
  
  
  `;

  export default GlobalStyles;
